import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import Navbar from "../../components/navbar/navbar"
import NavbarMobile from "../../components/navbar/navbarMobile"
import VideosSection from "../../components/servicos/videosSection"
import Footer from "../../components/footer/footer"
import FooterMobile from "../../components/footer/footerMobile"


import FormServicos from "../../components/servicos/formServicos"
import BannerTextInfoMobile from "../../components/servicos/BannerTextInfoMobile"
import BannerTextInfo from "../../components/servicos/BannerTextInfo"
import ServiceTextDoctor from "../../components/servicos/ServiceTextDoctor"
import ServiceTextDoctorMobile from "../../components/servicos/ServiceTextDoctorMobile"
import BannerSlideInfo from "../../components/servicos/BannerSlideInfo"
import BannerSlideInfoMobile from "../../components/servicos/BannerSlideInfoMobile"
import FormServicosMobile from "../../components/servicos/formServicosMobile"

const OdontopediatriaPage = ({ data }) => (
  <Layout>
    <SEO
      title="Odontopediatria"
      description={data.odontopediatriaJson.intro.text}
    />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <BannerTextInfoMobile data={data.odontopediatriaJson.intro} />
        <VideosSection data={data.odontopediatriaJson.videos} />
        <BannerSlideInfoMobile info={data.odontopediatriaJson.content} />
        <ServiceTextDoctorMobile doctor={data.odontopediatriaJson.doctor} data={data.odontopediatriaJson} />
        <FormServicosMobile nome="Odontopediatria" />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <BannerTextInfo data={data.odontopediatriaJson.intro} />
        <VideosSection data={data.odontopediatriaJson.videos} />
        <BannerSlideInfo info={data.odontopediatriaJson.content} />
        <ServiceTextDoctor doctor={data.odontopediatriaJson.doctor} data={data.odontopediatriaJson} />
        <FormServicos nome="Odontopediatria" />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default OdontopediatriaPage

export const Json = graphql`
  query odontopediatria {
    odontopediatriaJson {
      intro {
        title
        text
        banner
        intro
      }
      consult
      doctor {
        name
        cargo
        omd
        img 
      }
      banner {
        childImageSharp {
          fluid(quality: 100, maxWidth: 4000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      alt
      content {
        aliviar {
          title
          text
        }
        queda {
          title
          text
        }
        consulta {
          title
          text
        }
        videoBanner{
          video {
            publicURL
          }
          poster {
            publicURL
          }
        }
        slide{
          title 
          subtitle
          img
          step
        }
      }
      videos{
        title
        eps{
          title
          video
          poster
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
