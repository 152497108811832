import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

import { Button } from "../buttons"
import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

const VideosSection = ({ data }) => {
  const [choice, setChoice] = useState(0)

  return (
    <StyledSobre>
      {/*<div className={font.rB + " title final-video"}>
        {data.title}
      </div>
      */}
      <div className="selection">
        {data.eps.map((item, i) => (
          <Button
            key={'btn-ep-' + i}
            styleless
            className={
              choice === i ? font.rSB + " selec active" : font.rSB + " selec"
            }
            onClick={() => setChoice(i)}
          >
            {item.title}
          </Button>
        ))}

      </div>
      <div className="container">
        {data.eps.map((item, i) => (
          <CSSTransition
            in={choice === i}
            timeout={350}
            classNames={"switch"}
            unmountOnExit
          >
            <div className="container-content">
              <video
                playsInline
                controls
                poster={item.poster}
              >
                <source src={item.video} type="video/mp4" />
              </video>
            </div>
          </CSSTransition>
        ))}
      </div>
    </StyledSobre>
  )
}

export default VideosSection

const StyledSobre = styled.div`
  position: relative;
  text-align: center;
  
  .title {
    font-size: calc(16px + 22 * (100vw - 320px) / 1600);

    @media only screen and (min-width: 1920px) {
      font-size: 38px;
    }

    letter-spacing: 0.1em;
    color: #231f20;
    text-transform: uppercase;
    margin-bottom: 2em;
  }
  .final-video {
    margin: 2em 0 2em;
    text-align: center;
  }
  .final-video ~ video {
    width: 100%;
  }
  .selection {
    display: flex;
    justify-content: center;
    margin: 5%;
    flex-direction: column;
    gap: 1rem;
   
    .selec {
      font-size: 16px;
      letter-spacing: 0.1em;
      position: relative;
      margin: 0 40px;
      @media (min-width: 991px) and (max-width: 1550px){
        margin: 0 20px;
      }
      
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 1.5rem;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .container {
    position: relative;
    .container-content {
      position: relative;
      text-align: center;
      video{
        width: 100%;
      }
    }
  }

  .switch-enter {
    position: relative;
    opacity: 0 !important;
  }
  .switch-enter-active {
    position: relative;
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit {
    position: absolute;
    top: 0 !important;
    opacity: 1 !important;
  }
  .switch-exit-active {
    position: absolute;
    top: 0 !important;
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
`
